import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          機構管理画面
        </Typography>
        <Button color="inherit" component={Link} to="/dashboard">Home</Button>
        <Button color="inherit" component={Link} to="/dashboard/users">Users</Button>
        <Button color="inherit" component={Link} to="/dashboard/settings">Settings</Button>
        <Button color="inherit" component={Link} to="/">Logout</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
