import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, Divider, Button, TextField, MenuItem, Switch } from '@mui/material';

const orders = [
  { id: 1, customer: 'エクソソーム１', date: '2024-07-01', status: '未受取' },
  { id: 2, customer: 'エクソソーム２', date: '2024-07-02', status: '未受取' },
  { id: 3, customer: 'エクソソーム３', date: '2024-07-03', status: '未受取' },
  // 追加のオーダーデータ
];

const productOptions = [
  'エクソソーム１',
  'エクソソーム２',
  'エクソソーム３',
];

const Orders: React.FC = () => {
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [orderList, setOrderList] = useState(orders);
  const [formValues, setFormValues] = useState({
    productName: '',
    quantity: '',
    price: '',
  });

  const handleSelectOrder = (order: any) => {
    setSelectedOrder(order);
  };

  const handleDownloadPDF = () => {
    // PDFダウンロードロジックをここに追加
    const link = document.createElement('a');
    link.href = '/path/to/your/pdf'; // PDFのパスを指定
    link.download = 'order.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // フォーム送信ロジックをここに追加
    console.log('Form submitted:', formValues);
  };

  const toggleOrderStatus = (orderId: number) => {
    const updatedOrders = orderList.map((order) => {
      if (order.id === orderId) {
        const newStatus = order.status === '未受取' ? '受取済み' : '未受取';
        return { ...order, status: newStatus };
      }
      return order;
    });
    setOrderList(updatedOrders);
    if (selectedOrder && selectedOrder.id === orderId) {
      setSelectedOrder(updatedOrders.find((order) => order.id === orderId));
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        発注業務
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, maxHeight: '300px', overflow: 'auto' }}>
            {/* 高さを固定し、スクロール可能に */}
            <Typography variant="h6" gutterBottom>オーダーリスト</Typography>
            <List>
              {orderList.map((order) => (
                <React.Fragment key={order.id}>
                  <ListItem button onClick={() => handleSelectOrder(order)}>
                    <ListItemText
                      primary={`Order: ${order.customer}`}
                      secondary={`Date: ${order.date} - Status: ${order.status}`}
                    />
                    <Switch
                      checked={order.status === '受取済み'}
                      onChange={() => toggleOrderStatus(order.id)}
                      color="primary"
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2, height: '300px' }}>
            {/* 高さをオーダーリストと同じに設定 */}
            <Typography variant="h6" gutterBottom>オーダー詳細</Typography>
            {selectedOrder ? (
              <Box>
                <Typography variant="subtitle1">Customer: {selectedOrder.customer}</Typography>
                <Typography variant="subtitle1">Date: {selectedOrder.date}</Typography>
                <Typography variant="subtitle1">Status: {selectedOrder.status}</Typography>
                <Typography variant="subtitle1">Order ID: {selectedOrder.id}</Typography>
                {/* その他のオーダー詳細情報 */}
                <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleDownloadPDF}>
                  発注書ダウンロード
                </Button>
              </Box>
            ) : (
              <Typography variant="body1">オーダーを選択してください。</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6" gutterBottom>発注書作成</Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                select
                label="商品名"
                name="productName"
                value={formValues.productName}
                onChange={handleChange}
                fullWidth
                margin="normal"
              >
                {productOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="数量"
                name="quantity"
                value={formValues.quantity}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="価格"
                name="price"
                value={formValues.price}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                発注書作成
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Orders;
