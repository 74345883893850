import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Users from '../pages/Users';
import Settings from '../pages/Settings';
import OrderManagementPage from '../pages/OrderManagementPage';
import Orders from '../pages/Orders';
import Inventory from '../pages/Inventory';
import PointGrant from '../pages/PointGrant';
import TempAccount from '../pages/TempAccount';
import MasterAccount from '../pages/MasterAccount';
import TempAccountList from '../pages/TempAccountList';
import MasterAccountList from '../pages/MasterAccountList';
import Header from './Header';
import Sidebar from './Sidebar';
import { Box, CssBaseline, Toolbar } from '@mui/material';

const drawerWidth = 0; // サイドメニューの幅を300に設定

const Dashboard: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: `${drawerWidth}px`,
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="users" element={<Users />} />
          <Route path="settings" element={<Settings />} />
          <Route path="OrderManagementPage" element={<OrderManagementPage />} />
          <Route path="Orders" element={<Orders />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="point-grant" element={<PointGrant />} />
          <Route path="temp-account" element={<TempAccount />} />
          <Route path="master-account" element={<MasterAccount />} />
          <Route path="temp-account-list" element={<TempAccountList />} />
          <Route path="master-account-list" element={<MasterAccountList />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
