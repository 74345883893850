import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Container,
  TextField,
  Button,
} from '@mui/material';

// 各クリニックのポイントデータ
interface Clinic {
  id: number;
  name: string;
  currentPoints: number;
}

interface PointHistory {
  date: string;
  change: number;
  reason?: string; // 減少理由を示すためのオプショナルフィールド
}

const PointGrant: React.FC = () => {
  const [clinics, setClinics] = useState<Clinic[]>([
    { id: 1, name: 'AAAAクリニック', currentPoints: 100 },
    { id: 2, name: 'BBBBクリニック', currentPoints: 200 },
    { id: 3, name: 'CCCCクリニック', currentPoints: 150 },
    { id: 4, name: 'DDDDクリニック', currentPoints: 180 },
    { id: 5, name: 'EEEEクリニック', currentPoints: 220 },
  ]);

  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);

  const [pointHistory, setPointHistory] = useState<Record<number, PointHistory[]>>({
    1: [
      { date: '2024-07-01', change: 10 },
      { date: '2024-07-02', change: -5, reason: 'キャンセル' },
      { date: '2024-07-03', change: 15 },
      { date: '2024-07-04', change: -10, reason: '返品' },
      { date: '2024-07-05', change: 20 },
      { date: '2024-07-06', change: 5 },
      { date: '2024-07-07', change: -5, reason: '＊＊＊クリニック購入分' },
      { date: '2024-07-08', change: 10 },
      { date: '2024-07-09', change: -15, reason: '＊＊＊クリニック購入分' },
      { date: '2024-07-10', change: 20 },
    ],
    2: [
      { date: '2024-07-01', change: 20 },
      { date: '2024-07-02', change: 15 },
      { date: '2024-07-03', change: -10, reason: '返品' },
      { date: '2024-07-04', change: 5 },
      { date: '2024-07-05', change: -5, reason: 'キャンセル' },
      { date: '2024-07-06', change: 25 },
      { date: '2024-07-07', change: 15 },
      { date: '2024-07-08', change: -10, reason: '＊＊＊クリニック購入分' },
      { date: '2024-07-09', change: 30 },
      { date: '2024-07-10', change: -5, reason: '＊＊＊クリニック購入分' },
    ],
    3: [
      { date: '2024-07-01', change: 5 },
      { date: '2024-07-02', change: -5, reason: 'キャンセル' },
      { date: '2024-07-03', change: 10 },
      { date: '2024-07-04', change: 20 },
      { date: '2024-07-05', change: -10, reason: '返品' },
      { date: '2024-07-06', change: 15 },
      { date: '2024-07-07', change: 10 },
      { date: '2024-07-08', change: -15, reason: '＊＊＊クリニック購入分' },
      { date: '2024-07-09', change: 5 },
      { date: '2024-07-10', change: 10 },
    ],
    4: [
      { date: '2024-07-01', change: 10 },
      { date: '2024-07-02', change: 5 },
      { date: '2024-07-03', change: -20, reason: '返品' },
      { date: '2024-07-04', change: 15 },
      { date: '2024-07-05', change: 10 },
      { date: '2024-07-06', change: -5, reason: 'キャンセル' },
      { date: '2024-07-07', change: 20 },
      { date: '2024-07-08', change: -10, reason: '＊＊＊クリニック購入分' },
      { date: '2024-07-09', change: 25 },
      { date: '2024-07-10', change: -5, reason: '＊＊＊クリニック購入分' },
    ],
    5: [
      { date: '2024-07-01', change: 30 },
      { date: '2024-07-02', change: -10, reason: '返品' },
      { date: '2024-07-03', change: 25 },
      { date: '2024-07-04', change: 15 },
      { date: '2024-07-05', change: -5, reason: 'キャンセル' },
      { date: '2024-07-06', change: 20 },
      { date: '2024-07-07', change: 10 },
      { date: '2024-07-08', change: 5 },
      { date: '2024-07-09', change: -10, reason: '＊＊＊クリニック購入分' },
      { date: '2024-07-10', change: 15 },
    ],
  });

  const [pointInputs, setPointInputs] = useState<Record<number, string>>({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  });

  const handleSelectClinic = (clinic: Clinic) => {
    setSelectedClinic(clinic);
  };

  const handlePointChange = (id: number, value: string) => {
    setPointInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  const handlePointGrant = (clinicId: number) => {
    const clinic = clinics.find((clinic) => clinic.id === clinicId);
    if (!clinic) return;

    const change = parseInt(pointInputs[clinicId], 10);
    if (isNaN(change)) {
      alert('有効なポイント数を入力してください。');
      return;
    }

    const updatedClinics = clinics.map((clinic) =>
      clinic.id === clinicId
        ? { ...clinic, currentPoints: clinic.currentPoints + change }
        : clinic
    );

    const updatedHistory = [
      ...pointHistory[clinicId],
      {
        date: new Date().toISOString().split('T')[0],
        change: change,
        reason: change < 0 ? '手動ポイント減少' : undefined,
      },
    ];

    setClinics(updatedClinics);
    setPointHistory((prevHistory) => ({
      ...prevHistory,
      [clinicId]: updatedHistory,
    }));

    setPointInputs((prevInputs) => ({
      ...prevInputs,
      [clinicId]: '',
    }));
  };

  const sortHistoryByDate = (history: PointHistory[]) => {
    return history.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          ポイント付与
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, maxHeight: '500px', overflow: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                クリニックリスト
              </Typography>
              <List>
                {clinics.map((clinic) => (
                  <React.Fragment key={clinic.id}>
                    <ListItem button onClick={() => handleSelectClinic(clinic)}>
                      <ListItemText
                        primary={clinic.name}
                        secondary={`現在のポイント: ${clinic.currentPoints}`}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          width: '200px',
                        }}
                      >
                        <TextField
                          label="ポイント数"
                          value={pointInputs[clinic.id]}
                          onChange={(e) =>
                            handlePointChange(clinic.id, e.target.value)
                          }
                          size="small"
                          sx={{ width: '570px', mr: 1 }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handlePointGrant(clinic.id)}
                        >
                          付与
                        </Button>
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, maxHeight: '450px', overflow: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                ポイント履歴
              </Typography>
              {selectedClinic ? (
                <List>
                  {sortHistoryByDate(pointHistory[selectedClinic.id])?.map(
                    (entry, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`日付: ${entry.date}`}
                            secondary={`ポイント変動: ${
                              entry.change > 0 ? '+' : ''
                            }${entry.change}${
                              entry.reason ? ` - 理由: ${entry.reason}` : ''
                            }`}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              ) : (
                <Typography variant="body1">
                  クリニックを選択してください。
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PointGrant;
