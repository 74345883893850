import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Toolbar } from '@mui/material';
import { Home, Person, Settings, Assignment, Inventory, Loyalty, AccountBox, ListAlt } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 300; // サイドメニューの幅を300に設定

const Sidebar: React.FC = () => {
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/dashboard/orders">
          <ListItemIcon><Assignment /></ListItemIcon>
          <ListItemText primary="発注業務" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/OrderManagementPage">
          <ListItemIcon><Assignment /></ListItemIcon>
          <ListItemText primary="受注業務" />
        </ListItem>
        
        <ListItem button component={Link} to="/dashboard/inventory">
          <ListItemIcon><Inventory /></ListItemIcon>
          <ListItemText primary="在庫管理" />
        </ListItem>

        <ListItem button component={Link} to="/dashboard/point-grant">
          <ListItemIcon><Loyalty /></ListItemIcon>
          <ListItemText primary="ポイント発行" />
        </ListItem>
        {/*
        <ListItem button component={Link} to="/dashboard/master-account">
          <ListItemIcon><AccountBox /></ListItemIcon>
          <ListItemText primary="アカウント発行（マスター）" />
        </ListItem>
        */}
        <Divider />
        <ListItem button component={Link} to="/dashboard/temp-account-list">
          <ListItemIcon><ListAlt /></ListItemIcon>
          <ListItemText primary="登録クリニック一覧" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/temp-account">
          <ListItemIcon><AccountBox /></ListItemIcon>
          <ListItemText primary="アカウント発行" />
        </ListItem>
         {/*
        <ListItem button component={Link} to="/dashboard/master-account-list">
          <ListItemIcon><ListAlt /></ListItemIcon>
          <ListItemText primary="アカウント一覧（マスター）" />
        </ListItem>
        */}
      </List>
    </Drawer>
  );
};

export default Sidebar;
