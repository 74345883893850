import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// 棒グラフのデータとオプション
const inventoryData = {
  labels: ['7/4', '7/5', '7/6', '7/7', '7/8', '7/9', '7/10'],
  datasets: [
    {
      label: '在庫数',
      data: [50, 45, 55, 60, 65, 70, 75],
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '日数による在庫推移',
    },
  },
};

// インベントリアイテムインターフェース
interface InventoryItem {
  name: string;
  stock: number;
}

const Inventory: React.FC = () => {
  const [inventory] = useState<InventoryItem[]>([
    { name: 'エクソソーム１', stock: 120 },
    { name: 'エクソソーム２', stock: 80 },
    { name: 'エクソソーム３', stock: 95 },
  ]);

  const clinics = [
    { name: 'AAAAクリニック', items: [{ name: 'エクソソーム１', stock: 20 }, { name: 'エクソソーム２', stock: 15 }] },
    { name: 'BBBBクリニック', items: [{ name: 'エクソソーム１', stock: 10 }, { name: 'エクソソーム３', stock: 25 }] },
    { name: 'CCCCクリニック', items: [{ name: 'エクソソーム２', stock: 5 }, { name: 'エクソソーム３', stock: 30 }] },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        在庫管理
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '500px' }}> {/* 高さを500pxに固定 */}
            <Typography variant="h6" gutterBottom>
              在庫推移（機構）
            </Typography>
            <Bar data={inventoryData} options={options} />
            <Typography variant="h6" sx={{ mt: 3 }}>
              現在の在庫数（機構）:
            </Typography>
            <ul>
              {inventory.map((item, index) => (
                <li key={index}>
                  {item.name}: {item.stock}個
                </li>
              ))}
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '500px', overflow: 'auto' }}> {/* 高さを500pxに固定し、スクロールを追加 */}
            <Typography variant="h6" gutterBottom>
              クリニック在庫表
            </Typography>
            {clinics.map((clinic, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">{clinic.name}</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>商品名</TableCell>
                        <TableCell>在庫数</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clinic.items.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.stock}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Inventory;
