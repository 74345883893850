import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Switch,
  Toolbar,
} from '@mui/material';

// サンプルデータ
const initialOrders = [
  {
    id: 1,
    products: [
      { name: 'エクソソームA', quantity: 2 },
    ],
    customer: 'AAAAクリニック',
    orderDate: '2024-07-01',
    status: '未発送',
  },
  {
    id: 2,
    products: [
      { name: 'エクソソームA', quantity: 2 },
      { name: 'エクソソームB', quantity: 5 },
    ],
    customer: 'BBBBクリニック',
    orderDate: '2024-07-02',
    status: '発送済み',
  },
  {
    id: 3,
    products: [
      { name: 'エクソソームA', quantity: 8 },
    ],
    customer: 'CCCCクリニック',
    orderDate: '2024-07-03',
    status: '未発送',
  },
  {
    id: 4,
    products: [
      { name: 'エクソソームA', quantity: 12 },
      { name: 'エクソソームC', quantity: 2 },
    ],
    customer: 'DDDDクリニック',
    orderDate: '2024-07-03',
    status: '未発送',
  },
  {
    id: 5,
    products: [
      { name: 'エクソソームA', quantity: 22 },
    ],
    customer: 'EEEEクリニック',
    orderDate: '2024-07-03',
    status: '未発送',
  },
  // 追加の受注データ
];

const OrderManagementPage: React.FC = () => {
  const [orders, setOrders] = useState(initialOrders);

  // 発送状態の切り替え
  const toggleShipmentStatus = (orderId: number) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId
          ? { ...order, status: order.status === '未発送' ? '発送済み' : '未発送' }
          : order
      )
    );
  };

  return (
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
        受注管理
      </Typography>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, maxHeight: 500, overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                受注リスト
              </Typography>
              <List>
                {orders.map((order) => (
                  <React.Fragment key={order.id}>
                    <ListItem sx={{ mb: 2 }}>
                      {/* 顧客名を一番上に大きく表示 */}
                      <Typography variant="h6" gutterBottom>
                        {order.customer}　　
                      </Typography>
                      <ListItemText
                        primary={
                          <>
                            {order.products.map((product, index) => (
                              <Typography key={index} variant="body1">
                                {`${product.name}: ${product.quantity}個`}
                              </Typography>
                            ))}
                          </>
                        }
                        secondary={`受注日: ${order.orderDate}`}
                      />
                      <Typography variant="body2" sx={{ mr: 2 }}>
                        状態: {order.status}
                      </Typography>
                      <Switch
                        checked={order.status === '発送済み'}
                        onChange={() => toggleShipmentStatus(order.id)}
                        color="primary"
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
      </Box>
  );
};

export default OrderManagementPage;
